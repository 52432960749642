<template>
  <div>
    <w-top-bar>Estadísticas</w-top-bar>

    <div class="h-full p-5 bg-gray-200">
      <div class="border-b border-gray-400 mb-2">
        <div class="flex items-center">
          <div class="flex-1 h-12 text-gray-900 items-center hidden md:flex">Resumen General</div>
          <w-date-range class="flex-1 h-12" v-model="dateFilter" @input="reload()" />

          <w-select
            label="Moneda"
            class2="z-20 w-24 md:h-12 "
            v-model="currency"
            :options="currencies"
          />
        </div>
      </div>
      <div
        v-if="dashboardStat"
        class="flex flex-wrap space-y-3 md:flex-nowrap md:space-y-0 md:space-x-6"
      >
        <div class="relative w-full overflow-hidden bg-white rounded-lg shadow md:w-1/3">
          <div class="flex items-center py-4">
            <div>
              <svg
                class="w-6 h-6 m-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="flex-grow text-xs leading-snug">
              <div class="font-medium text-gray-600">
                <span class="mr-1 font-semibold">{{ dashboardStat[currency].count }}</span
                >Ventas brutas
              </div>
              <div class="text-base font-semibold text-gray-900">
                <w-price-v-2 :currency="currency" :value="dashboardStat[currency].total" />
              </div>
            </div>
          </div>
          <div class="px-4 py-2 text-xs opacity-50 cursor-not-allowed bg-sand-500 text-base-500">
            Ver detalle
          </div>
        </div>

        <div class="w-full overflow-hidden bg-white rounded-lg shadow md:w-1/3">
          <div class="flex items-center py-4">
            <div>
              <svg
                class="w-6 h-6 m-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="flex-grow text-xs leading-snug">
              <div class="font-medium text-gray-600">
                <div class="font-medium text-gray-600">
                  <span class="mr-1 font-semibold">{{ dashboardStat[currency].countToday }}</span
                  >Ventas de hoy
                </div>
              </div>
              <div class="text-base font-semibold text-gray-900">
                <w-price-v-2 :currency="currency" :value="dashboardStat[currency].today" />
              </div>
            </div>
          </div>
          <div class="px-4 py-2 text-xs opacity-50 cursor-not-allowed bg-sand-500 text-base-500">
            Ver detalle
          </div>
        </div>

        <div class="w-full overflow-hidden bg-white rounded-lg shadow md:w-1/3">
          <div class="flex items-center py-4">
            <div>
              <svg
                class="w-6 h-6 m-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
                />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="flex-grow text-xs leading-snug">
              <div class="font-medium text-gray-600">Promedio por venta</div>
              <div class="text-base font-semibold text-gray-900">
                <w-price-v-2 :currency="currency" :value="averagePerSale" />
              </div>
            </div>
          </div>
          <div class="px-4 py-2 text-xs opacity-50 cursor-not-allowed bg-sand-500 text-base-500">
            Ver todo
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div v-if="dashboardStat">
          <w-total-sales
            :loading="isGetDashboardStatPending"
            :chart="dashboardStat.totalsByDay"
            :currency="currency"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeGetMixin } from "feathers-vuex"; // Step 1
import WPriceV2 from "../../components/WPriceV2.vue";
import moment from "moment";

export default {
  components: { WPriceV2 },
  name: "Dashboard",
  data() {
    return {
      dateFilter: {
        start: moment().subtract(15, "days").startOf("day").toDate(),
        end: moment().subtract(0, "days").endOf("day").toDate(),
      },
      currency: "ars",
      currencies: [
        { label: "USD", key: "usd" },
        { label: "ARS", key: "ars" },
      ],
    };
  },
  mixins: [
    makeGetMixin({ service: "dashboard-stats" }),
    // makeFindMixin({ service: "products", name: "pastproducts" }),
  ], // Step 2
  created() {},
  computed: {
    dashboardStatId() {
      return "dashboard";
    },
    dashboardStatParams() {
      return {
        query: {
          start: this.dateFilter.start,
          end: this.dateFilter.end,
          key: "FVOJDAINGEOGVNVEG",
        },
      };
    },
    averagePerSale() {
      const totals = this.dashboardStat[this.currency];
      return totals.total / totals.count || 0;
    },
  },
  methods: {
    reload() {
      console.log("reload");
      this.getDashboardStat();
    },
  },
};
</script>
